@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';






















































.registration-complete__text {
  &--big {
    font-weight: 700;
    font-size: size(26);
  }
  &--small {
    font-weight: 400;
    font-size: size(19);
  }
}
